import { styled } from "@hoken/core/styles/stitches.config";

import { varsHoken } from "@hoken/design-system";

export const HeaderDesktopWrapper = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "90px",
  width: "100%",
  transition: "background-color 0.3s",

  variants: {
    textColor: {
      black: {
        a: {
          color: "$black",
        },
        ".logo-mark path": {
          fill: "$primary",
        },
      },
      white: {
        color: "$white",
      },
      blackTextWhiteAvatar: {
        a: {
          color: "$black",
          "&.avatar--button": {
            color: "$primary",
          },
        },
        path: {
          fill: "$black",
        },
      },
    },
    blackBg: {
      true: {
        backgroundColor: "$black",
        transition: "background-color 0.7s",
        div: {
          a: {
            color: "$white",
            "&.avatar--button": {
              color: "$green",
            },
          },
          ".logo-mark path": {
            fill: "$green",
          },
        },
      },
    },
  },
});

export const HeaderDesktopStickyWrapper = styled("header", {
  position: "fixed",
  zIndex: "4",
  width: "100%",
  top: 0,
  variants: {
    sticky: {
      true: {
        position: "sticky",
      },
    },
  },
});

export const HeaderDesktopMaxWidthContainer = styled("div", {
  maxWidth: "1368px",
  margin: "0px auto",
  width: "100%",
  display: "flex",
  padding: `0 ${varsHoken.space["xxlarge"]}`,
  gap: "3rem",
  justifyContent: "space-between",
});
