import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Times,
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastViewport,
  clearRedirectWithError,
  hasRedirectWithErrorParam,
  useRouter,
} from "@hoken/core";

/**
 * Component will render when the client URL matches the query parameter of URL_ERROR_PARAM_KEY
 * Defined in packages/core/utilities/constants.ts
 */
export const ErrorAlert = () => {
  const dismissInSeconds = 3000;
  const router = useRouter();
  const [displayErrorAlert, setDisplayErrorAlert] = useState(() =>
    hasRedirectWithErrorParam(),
  );

  useEffect(() => {
    const clearedURL = clearRedirectWithError();
    if (clearedURL) {
      router.push(clearedURL, clearedURL, { shallow: true });
    }
  }, [router]);

  return (
    <ToastProvider swipeDirection='left' duration={dismissInSeconds}>
      <Toast
        css={{ backgroundColor: "#f9d3d1" }}
        type='error'
        open={displayErrorAlert}
        duration={dismissInSeconds}
      >
        <Box css={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <ToastDescription>Something went wrong on our end.</ToastDescription>
            <ToastDescription>Please try again.</ToastDescription>
          </Box>
          <ToastAction asChild altText='Close error alert message'>
            <ToastClose asChild>
              <Button
                type='icon'
                aria-label='Close error alert message'
                data-testid='error-alert-close'
                onClick={() => setDisplayErrorAlert(false)}
              >
                <Times />
              </Button>
            </ToastClose>
          </ToastAction>
        </Box>
      </Toast>
      <ToastViewport />
    </ToastProvider>
  );
};
