import { useEffect, useState } from "react";

import { Link } from "@hoken/core";

import { Avatar } from "../header/avatar/avatar";
import { Logo } from "../header/logo/logo";
import { Menu } from "../header/menu/menu";
import { MobileNavProps } from "./mobile-nav.interfaces";
import { headerTop, headerWrapper, searchButton } from "./mobile-nav.vanilla.css";

import { Icons } from "@hoken/design-system";

type HeaderState = 0 | 1 | 2 | undefined;

export const MobileNav = ({ lightTheme }: MobileNavProps): JSX.Element => {
  const [scrollPosition, setScrollPosition] = useState<HeaderState>(0);
  const headerBackgroundHandler = () => {
    if (window.scrollY < 30) {
      setScrollPosition(0);
      return;
    } else if (window.scrollY > 30 && window.scrollY < 390) {
      setScrollPosition(1);
      return;
    }
    setScrollPosition(2);
  };
  useEffect(() => {
    window.addEventListener("scroll", headerBackgroundHandler);
    headerBackgroundHandler();
    return () => window.removeEventListener("scroll", headerBackgroundHandler);
  }, []);
  return (
    <header
      className={headerWrapper({
        lightTheme: lightTheme,
        sticky: true,
        state: scrollPosition,
      })}
    >
      <div className={headerTop}>
        <Logo fill={lightTheme ? "black" : undefined} />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "1rem" }}>
            <Avatar lightTheme={lightTheme} />
          </div>
          <Menu />
        </div>
      </div>
      <Link className={searchButton} href='/events/search'>
        Search your event, find your hotel…
        <Icons.Search color='#5bfcb8' />
      </Link>
    </header>
  );
};
