import { varsHoken } from "@hoken/design-system";
import { style } from "@vanilla-extract/css";
import { recipe } from "@vanilla-extract/recipes";

export const headerWrapper = recipe({
  base: {
    display: "flex",
    flexDirection: "column",
    gap: varsHoken.space.small,
    padding: `${varsHoken.space.medium} ${varsHoken.space.medium}`,
    position: "relative",
    zIndex: 4,
  },
  variants: {
    sticky: {
      true: {
        position: "sticky",
        top: 0,
        left: 0,
        transition: "background-color 0.3s",
      },
    },
    lightTheme: {
      true: {
        color: varsHoken.colors.black,
        fill: varsHoken.colors.black,
      },
      false: {
        color: varsHoken.colors.white,
      },
    },
    state: {
      0: {
        backgroundColor: "transparent",
      },
      1: {
        backgroundColor: varsHoken.colors.black,
      },
      2: {
        backgroundColor: varsHoken.colors.black,
      },
    },
  },
  compoundVariants: [
    {
      variants: {
        lightTheme: true,
        state: 1,
      },
      style: {
        backgroundColor: varsHoken.colors.white,
      },
    },
    {
      variants: {
        lightTheme: true,
        state: 2,
      },
      style: {
        backgroundColor: varsHoken.colors.white,
      },
    },
  ],
  defaultVariants: {
    lightTheme: false,
  },
});

export const headerTop = style({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const headerState2Class = headerWrapper.classNames.variants.state[2];

export const searchButton = style({
  borderRadius: varsHoken.space.xsmall,
  border: `1px solid ${varsHoken.colors.green100}`,
  display: "flex",
  fontFamily: varsHoken.fonts.family.helvetica,
  fontSize: varsHoken.fonts.sizes.xxsmall,
  justifyContent: "space-between",
  alignItems: "center",
  maxHeight: 0,
  padding: `0 ${varsHoken.space.medium}`,
  opacity: 0,
  transition: "max-height 0.3s, padding 0.3s, opacity 0.3s",
  selectors: {
    [`${headerState2Class} > &`]: {
      maxHeight: "40px",
      padding: `${varsHoken.space.small} ${varsHoken.space.medium}`,
      opacity: 1,
    },
  },
});
