import { styled } from "@hoken/core/styles/stitches.config";

export const Title = styled("h2", {
  fontSize: "$hoken-core-font-20",
  lineHeight: "1.75rem",
  fontWeight: "300",
  fontFamily: "$helvetica",

  variants: {
    black: {
      true: {
        color: "$black",
      },
    },
    white: {
      true: {
        color: "$white",
      },
    },
    desktop: {
      true: {
        fontSize: "$hoken-core-font-24",
      },
    },
    align: {
      center: {
        textAlign: "center",
      },
    },
  },
});
