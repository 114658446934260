// Dependencies
import { useEffect, useState } from "react";

import { DropsTicker, ErrorAlert } from "@hoken/core";

import { HeaderDesktopNavigation } from "./header-desktop-navigation/header-desktop-navigation";
import { HeaderDesktopUser } from "./header-desktop-user/header-desktop-user";
// Interfaces
import { HeaderDesktopProps } from "./header-desktop.interfaces";
// Styles
import {
  HeaderDesktopMaxWidthContainer,
  HeaderDesktopWrapper,
} from "./header-desktop.styles";

export const HeaderDesktop = ({ textColor, showTicker, blackBg }: HeaderDesktopProps) => {
  const [isHeaderScrolling, setIsHeaderScrolling] = useState<boolean>(false);
  const headerBackgroundHandler = () => {
    if (window.scrollY < 30) {
      setIsHeaderScrolling(false);
      return;
    }
    setIsHeaderScrolling(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", headerBackgroundHandler);
    headerBackgroundHandler();
    return () => window.removeEventListener("scroll", headerBackgroundHandler);
  }, []);
  const lightTheme = !isHeaderScrolling && textColor === "black" ? true : false;
  return (
    <>
      <ErrorAlert />
      {showTicker && <DropsTicker />}
      <HeaderDesktopWrapper blackBg={isHeaderScrolling || blackBg} textColor={textColor}>
        <HeaderDesktopMaxWidthContainer>
          <HeaderDesktopNavigation />
          <HeaderDesktopUser lightTheme={lightTheme} />
        </HeaderDesktopMaxWidthContainer>
      </HeaderDesktopWrapper>
    </>
  );
};
