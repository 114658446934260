import { URL_ERROR_PARAM_KEY } from "@hoken/core";

/**
 * Consistently append error query param
 */
export const redirectWithError = (url: string) => {
  const onClient = typeof window !== "undefined";

  if (onClient) {
    const urlWithError = new URL(url, window.location.origin);
    urlWithError.searchParams.append(URL_ERROR_PARAM_KEY, "true");
    return urlWithError;
  }
};

export const hasRedirectWithErrorParam = () => {
  const onClient = typeof window !== "undefined";
  if (onClient) {
    const url = new URL(window.location.href);
    return url.search.replace("?", "").split("&").includes(`${URL_ERROR_PARAM_KEY}=true`);
  }

  return false;
};

/**
 * Clear error query param
 */

export const clearRedirectWithError = () => {
  const onClient = typeof window !== "undefined";

  if (onClient) {
    const url = new URL(window.location.href);
    if (hasRedirectWithErrorParam()) {
      url.searchParams.delete(URL_ERROR_PARAM_KEY);
      return url;
    }
  }
};
