import { styled } from "@hoken/core/styles/stitches.config";

import * as ToastPrimitive from "@radix-ui/react-toast";
import { keyframes } from "@stitches/react";

const hide = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const StyledViewport = styled(ToastPrimitive.Viewport, {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "100vw",
});

const StyledToast = styled(ToastPrimitive.Root, {
  zIndex: 99,
  padding: "$hoken-core-space-small",
  color: "$hoken-core-text-color",
  '&[data-state="closed"]': {
    animation: `${hide} 200ms ease-in`,
  },
  '&[data-swipe="cancel"]': {
    animation: `${hide} 200ms ease-in`,
  },
  "@reduceMotion": {
    '&[data-state="closed"]': {
      animation: "none",
    },
    '&[data-swipe="cancel"]': {
      animation: "none",
    },
  },

  variants: {
    type: {
      error: {
        backgroundColor: "$hoken-core-red-error",
      },
    },
  },
});

const StyledTitle = styled(ToastPrimitive.Title, {});

const StyledDescription = styled(ToastPrimitive.Description, {});

const StyledAction = styled(ToastPrimitive.Action, {});

export const ToastProvider = ToastPrimitive.Provider;
export const ToastViewport = StyledViewport;
export const Toast = StyledToast;
export const ToastTitle = StyledTitle;
export const ToastDescription = StyledDescription;
export const ToastAction = StyledAction;
export const ToastClose = ToastPrimitive.Close;
