import { useEffect, useState } from "react";

import { config } from "@hoken/core/styles/stitches.config";

import { Breakpoint } from "./useBreakpoint.interfaces";

/**
 * Custom hook to programmatically control our UI for different displays.
 * Breakpoint types are inferred from the stiches theme media object.
 * Does manage expensive checks with client javascript. Leverages the matchMedia web api.
 */
export const useBreakpoint = (breakpoint: Breakpoint): boolean | undefined => {
  const [isMatch, setIsMatch] = useState<boolean>();
  const media = config.media;
  const mediaQuery = media[breakpoint];

  useEffect(() => {
    const media = window.matchMedia(mediaQuery);
    if (media.matches !== isMatch) {
      setIsMatch(media.matches);
    }
    const listener = () => {
      setIsMatch(media.matches);
    };

    media.addListener(listener);

    return () => {
      media.removeListener(listener);
    };
  }, [isMatch, mediaQuery]);

  return isMatch;
};
