import { config, styled } from "@hoken/core/styles/stitches.config";

import type * as Stitches from "@stitches/react";

const legacyButton = {
  borderRadius: "2px",
  fontSize: "$small",
  padding: "1.18rem",
  lineHeight: "$base",
  color: "$white",
  fontWeight: "$bold",
  fontFamily: "$helvetica",
  letterSpacing: "0.05rem",
  cursor: "pointer",

  variants: {
    // Todo: Remove all props using this variant as it does not support types and breaks buttons native disable
    disabled: {
      true: {
        backgroundColor: "$grey60",
        color: "$grey70",
      },
    },
    isDisabled: {
      light: {
        backgroundColor: "$grey60",
        color: "#white",
      },
    },
    type: {
      primary: {
        backgroundColor: "$primary",
      },
      secondary: {
        backgroundColor: "$green",
        color: "$black",
      },
      tertiary: {
        border: "solid 1px $primary",
        color: "$primary",
      },
      link: {
        background: "transparent",
        color: "$green",
      },
      icon: {
        backgroundColor: "initial",
        color: "initial",
        padding: "$0",
      },
      text: {
        backgroundColor: "initial",
        padding: "$0",
        color: "$primary",
        fontWeight: "$regular",
        fontFamily: "$franklin",
        letterSpacing: "0",
      },
    },

    size: {
      "extra-small": {
        padding: "$1 $2",
        fontSize: "$small",
      },
    },
  },
};

const CTA_LINK: Stitches.CSS<typeof config> = {
  fontFamily: "$helvetica",
  fontStyle: "normal",
  fontWeight: "$regular",
  fontSize: "$hoken-core-font-13",
  lineHeight: "$hoken-core-line-height-16",
  display: "flex",
  alignItems: "center",
  textTransform: "uppercase",
  padding: "$0",
  width: "auto",
  color: "$primary",
};

export const Button = styled("button", {
  ...legacyButton,
  ...{
    variants: {
      ...legacyButton.variants,
      type: {
        ...legacyButton.variants.type,
        CTA_LINK: CTA_LINK,
        CTA_LINK_Dark: { ...CTA_LINK, color: "$green" },
      },
      icon: {
        left: {
          "&": {
            // Resolve issue with svg not center aligning with text height
            alignItems: "baseline",
          },
          "& svg": {
            marginLeft: "$0",
            marginRight: "0.4688rem",
          },
        },
        right: {
          "&": {
            // Resolve issue with svg not center aligning with text height
            alignItems: "baseline",
          },
          "& svg": {
            marginRight: "$0",
            marginLeft: "0.4688rem",
          },
        },
      },
    },
  },
});

export const HokenButton = styled("button", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "$hoken-core-space-16",
  gap: "$hoken-core-space-8",
  width: "100%",
  borderRadius: "2px",
  fontFamily: "$helvetica",
  fontWeight: "$bold",
  cursor: "pointer",
  variants: {
    type: {
      BRAND_PRIMARY: {
        color: "$white",
        textTransform: "uppercase",
        backgroundColor: "$primary",
        border: "1px solid $primary",
        "&:hover": {
          backgroundColor: "#3329A1",
        },
        "&:focus, &:focus-visible": {
          border: "1px solid $green",
          outline: "$green auto 2px",
        },
      },
      BRAND_PRIMARY_DARK: {
        color: "$black",
        textTransform: "uppercase",
        backgroundColor: "$green",
        "&:hover": {
          backgroundColor: "$greenTint",
        },
        "&:focus, &:focus-visible": {
          outline: "$white auto 2px",
        },
      },
      SECONDARY: {
        color: "$primary",
        textTransform: "uppercase",
        backgroundColor: "$white",
        border: "2px solid $primary",
        "&:hover": {
          backgroundColor: "#E5E2FF",
        },
        "&:focus, &:focus-visible": {
          backgroundColor: "#E5E2FF",
          border: "2px solid $primary",
          outline: "$primary auto 2px",
        },
      },
      CTA_LINK: CTA_LINK,
      CTA_LINK_Dark: { ...CTA_LINK, color: "$green" },
      CANCEL: {
        color: "$red20",
        textTransform: "uppercase",
        backgroundColor: "#FFECEC",
        border: "1px solid $red20",
      },
      LINK: {
        color: "$primary",
        fontFamily: "$franklin",
        letterSpacing: 0,
        padding: 0,
        fontWeight: "bold",
        height: "auto",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      PILL: {
        border: "1px solid $hoken-core-greyscale-80",
        borderRadius: "$hoken-core-border-radius-10",
        color: "$white",
        fontFamily: "$franklin",
        fontSize: "$hoken-core-font-15",
      },
      PILL_ACTIVE: {
        borderRadius: "$hoken-core-border-radius-10",
        color: "$white",
        fontFamily: "$franklin",
        fontSize: "$hoken-core-font-15",
        gradientBorder: {
          overrides: {
            backgroundColor: "$grey90",
            borderRadius: "10px",
            border: "1px solid transparent",
          },
        },
      },
    },
    size: {
      large: {
        height: "50px",
        fontSize: "$hoken-core-font-13",
        lineHeight: "$hoken-core-font-15",
        letterSpacing: "0.05em",
      },
      medium: {
        height: "40px",
        fontSize: "$hoken-core-font-13",
        letterSpacing: "0.05em",
      },
      small: {
        height: "auto",
        fontSize: "$hoken-core-font-10",
        letterSpacing: "0.05em",
        whiteSpace: "nowrap",
        paddingTop: "$hoken-core-space-12",
        paddingBottom: "$hoken-core-space-10", // padding bottom is 10px to compensate the font metrics. TO DO find a better version of the font to fix this.
      },
    },
    transform: {
      lowercase: {
        textTransform: "lowercase",
      },
      uppercase: {
        textTransform: "uppercase",
      },
      capitalize: {
        textTransform: "capitalize",
      },
      inherit: {
        textTransform: "inherit",
      },
    },
    icon: {
      left: {
        "&": {
          // Resolve issue with svg not center aligning with text height
          alignItems: "baseline",
        },
        "& svg": {
          marginLeft: "$0",
          marginRight: "0.4688rem",
        },
      },
      right: {
        "&": {
          // Resolve issue with svg not center aligning with text height
          alignItems: "baseline",
        },
        "& svg": {
          marginRight: "$0",
          marginLeft: "0.4688rem",
        },
      },
    },
    disabled: {
      true: {
        pointerEvents: "none",
        backgroundColor: "$grey60",
        color: "$grey70",
        border: "0",
        "&:hover": {
          backgroundColor: "$grey60",
          color: "$grey70",
        },
        "&:focus": {
          border: "$grey60",
          outline: "$grey60",
        },
      },
    },
  },
  defaultVariants: {
    type: "BRAND_PRIMARY",
    size: "large",
  },
  compoundVariants: [
    {
      type: "CANCEL",
      size: "medium",
      css: {
        fontSize: "$hoken-core-font-10",
      },
    },
  ],
});
