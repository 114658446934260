import { Fragment, useState } from "react";

import { Hamburger, HokenLogo, Link, useRouter, useUser } from "@hoken/core";

import { CONTENT } from "../content";
import { DialogContainerProps } from "../header.interface";
import {
  DialogBackgroundWrapper,
  DialogFooterWrapper,
  DialogMenuItem,
  DialogPanelInnerWrapper,
  DialogPanelOuterWrapper,
  DialogWrapper,
} from "./menu.style";

import { Dialog, Menu as TailwindMenu, Transition } from "@headlessui/react";
import { Text } from "@hoken/design-system";
import { Cross2Icon } from "@radix-ui/react-icons";

const DialogBackground = () => {
  return (
    <Transition.Child
      as={Fragment}
      enter='ease-out duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='ease-out duration-300'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <DialogBackgroundWrapper />
    </Transition.Child>
  );
};

const DialogContainer = (props: DialogContainerProps) => {
  const { isTypeUser } = useUser();
  const setOpen = props.setOpen;
  const MenuItems = isTypeUser ? CONTENT.menuSigned : CONTENT.menu;
  const router = useRouter();

  const samePageHandler = (link: string) => {
    const isSamePage = link === router.pathname;
    if (isSamePage) {
      setOpen(false);
    }
  };

  return (
    <DialogWrapper className='navigation-panel--wrapper'>
      <Transition.Child
        className='navigation-panel--wrapper'
        enter='transform transition ease-out duration-300 sm:duration-300'
        enterFrom='translate-x-full'
        enterTo='translate-x-0'
        leave='transform transition ease-out duration-300 sm:duration-300'
        leaveFrom='translate-x-0'
        leaveTo='translate-x-full'
      >
        <Dialog.Panel className='relative navigation-panel--wrapper'>
          <DialogPanelOuterWrapper>
            <DialogPanelInnerWrapper>
              <Link href='/' legacyBehavior>
                <a className='outline-0'>
                  <Dialog.Title className='text-3xl leading-6 font-medium outline-0'>
                    <HokenLogo
                      css={{
                        marginLeft: 0,
                        "&:focus, &:focus-visible": {
                          outline: "0",
                        },
                      }}
                    />
                  </Dialog.Title>
                </a>
              </Link>
              <Cross2Icon
                className='navigation-panel--close'
                onClick={() => setOpen(false)}
              />
            </DialogPanelInnerWrapper>
            <TailwindMenu as='nav'>
              <TailwindMenu.Items
                as='ul'
                static
                className='focus-visible:outline-none text-base'
              >
                {MenuItems.map((menuItem, index: number) => {
                  const name = menuItem.name;
                  const link = menuItem.link;

                  return (
                    <TailwindMenu.Item key={index} as={Fragment}>
                      {() => {
                        return (
                          <DialogMenuItem>
                            <Link href={link} legacyBehavior>
                              <a onClick={() => samePageHandler(link)}>
                                <p className='navigation-panel--anchor'>{name}</p>
                              </a>
                            </Link>
                          </DialogMenuItem>
                        );
                      }}
                    </TailwindMenu.Item>
                  );
                })}
              </TailwindMenu.Items>
            </TailwindMenu>
            <DialogFooterWrapper>
              <li>
                <Link href='/about-hoken' legacyBehavior>
                  <a>About Hoken</a>
                </Link>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://hoken.zendesk.com/hc/en-us'
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://hoken.zendesk.com/hc/en-us'
                >
                  Support
                </a>
                <Text
                  type='P3'
                  style={{ marginTop: "1rem", textTransform: "capitalize" }}
                >
                  <Link href='tel:8312085498'>Need Help Booking?: 831-208-5498</Link>
                </Text>
              </li>
            </DialogFooterWrapper>
          </DialogPanelOuterWrapper>
        </Dialog.Panel>
      </Transition.Child>
    </DialogWrapper>
  );
};

export const Menu = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Hamburger onClick={() => setOpen(true)} />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='section' onClose={setOpen}>
          <DialogBackground />
          <DialogContainer setOpen={setOpen} />
        </Dialog>
      </Transition.Root>
    </>
  );
};
