import { styled } from "@hoken/core/styles/stitches.config";

export const HeaderDesktopUserWrapper = styled("div", {
  color: "$white",
  display: "flex",
  gap: "3rem",
  alignItems: "center",
  a: {
    fontFamily: "$helvetica",
    fontSize: "$hoken-core-font-14",
    textTransform: "uppercase",
    marginBottom: "-0.3rem",
  },
});
