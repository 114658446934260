import { varsHoken } from "@hoken/design-system";
import { style } from "@vanilla-extract/css";

export const navigationWrapper = style({
  display: "flex",
  flexGrow: 1,
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

export const navigationItem = style({
  marginLeft: "2.3rem",
  textTransform: "uppercase",
  fontFamily: varsHoken.fonts.family.helvetica,
  fontSize: varsHoken.fonts.sizes.small,
});
