// Dependencies
import { HokenLogo, Link } from "@hoken/core";

// Styles
import { LogoWrapper } from "./logo.styles";

export const Logo = ({ fill }: { fill?: "black" }): JSX.Element => {
  return (
    <LogoWrapper>
      <Link href='/' passHref legacyBehavior>
        <a>
          <HokenLogo fill={fill} />
        </a>
      </Link>
    </LogoWrapper>
  );
};
