import { useEffect, useState } from "react";

import { useAuthentication } from "@hoken/core";

import { DropsV1EndpointResponse } from "../drops-endpoint.interfaces";

import useSWR from "swr";

const NEXT_PUBLIC_API = process.env.NEXT_PUBLIC_API;

export const useDrops = () => {
  const { session } = useAuthentication();
  const token = session?.token;
  const endpoint = `${NEXT_PUBLIC_API}/api/v1/drops`;
  const request = token ? { endpoint: endpoint, token: token } : null;
  const [preFetch, setPreFetch] = useState<DropsV1EndpointResponse>();

  const { data, error } = useSWR<DropsV1EndpointResponse>(request);
  const isLoading = !data && !error;

  useEffect(() => {
    if (data?.data) setPreFetch(data);
  }, [data]);

  return { data: data?.data || preFetch?.data, isLoading, error };
};
