// Dependencies
import { Link, useUser } from "@hoken/core";

// Interfaces
import { AvatarProps } from "./avatar.interfaces";
// Styles
import { AvatarStyle } from "./avatar.style";

import { Icons } from "@hoken/design-system";

export const Avatar = ({ lightTheme }: AvatarProps) => {
  const { data, isTypeUser, isLoading, error } = useUser();
  const href = isTypeUser ? "/my-account" : "/sign-in";
  const first = data?.first_name || "Go";
  const last = data?.last_name || "Hoken";
  const displayText = isTypeUser
    ? `${first?.charAt(0)}${last?.charAt(0)}`
    : "Get started";
  // User is either anonymous or the application is determining if their authentication type
  if (!isTypeUser) {
    return (
      <Link href={href} passHref>
        <AvatarStyle
          className='avatar--button'
          lightTheme={lightTheme}
          isAuthenticated={false}
        >
          <Icons.User />
        </AvatarStyle>
      </Link>
    );
  }

  // User is authenticated as a user and their profile data is being fetched
  if (isLoading) {
    return (
      <AvatarStyle lightTheme={lightTheme} isAuthenticated={isTypeUser}></AvatarStyle>
    );
  }

  if (error) {
    // TODO error scenario
  }

  return (
    <Link href={href} passHref>
      <AvatarStyle
        className='avatar--button'
        lightTheme={lightTheme}
        isAuthenticated={true}
      >
        {displayText}
      </AvatarStyle>
    </Link>
  );
};
