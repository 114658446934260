// Dependencies
import { Link, useUser } from "@hoken/core";

import { Avatar } from "../../header/avatar/avatar";
// Styles
import { HeaderDesktopUserWrapper } from "./header-desktop-user.styles";

export const HeaderDesktopUser = ({
  lightTheme,
}: {
  greenAvatarBg?: boolean;
  lightTheme?: boolean;
}): JSX.Element => {
  const { isTypeUser } = useUser();
  return (
    <HeaderDesktopUserWrapper>
      {isTypeUser ? (
        <>
          <Link href='/roomfolio'>Roomfolio</Link>
          <Avatar lightTheme={lightTheme} />
        </>
      ) : (
        <>
          <Avatar lightTheme={lightTheme} />
        </>
      )}
    </HeaderDesktopUserWrapper>
  );
};
