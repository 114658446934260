import { MarqueeProps } from "./marquee.interfaces";
import { MarqueeWrapper } from "./marquee.styles";

import FastMarquee from "react-fast-marquee";

export const Marquee = ({ children, speed = 15, loading = false }: MarqueeProps) => {
  return (
    <MarqueeWrapper open={loading}>
      <FastMarquee gradient={false} speed={speed}>
        {loading ? null : children}
      </FastMarquee>
    </MarqueeWrapper>
  );
};
