import { Box, HokenLogo, Link } from "@hoken/core";

import {
  navigationItem,
  navigationWrapper,
} from "./header-desktop-navigation.vanilla.css";

export const HeaderDesktopNavigation = (): JSX.Element => {
  return (
    <header className={navigationWrapper}>
      <Link href='/'>
        <HokenLogo />
      </Link>
      <Box
        as='nav'
        css={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "-0.3rem",
        }}
      >
        <Link className={navigationItem} href='/events'>
          Explore Events
        </Link>
        <Link className={navigationItem} href='/about-hoken'>
          About Hoken
        </Link>
        <Link className={navigationItem} href='https://hoken.zendesk.com/hc/en-us'>
          Support
        </Link>
      </Box>
    </header>
  );
};
