import { Fragment } from "react";

import { BlueLightning, Marquee, timestampToDate, useEvent } from "@hoken/core";

import { DropsCountdown } from "../drops-countdown";
import {
  DropTicketEventProps,
  DropsV1EndpointResponseItem,
} from "../drops-endpoint.interfaces";
import { useDrops } from "../hooks/use-drops";
import { DropTickerTitle } from "./drops-ticker-title.styles";

const DropsTickerEvent = ({ eventId, hotel }: DropTicketEventProps) => {
  const { data: event, isLoading } = useEvent(eventId);
  const eventName = event?.name;

  if (isLoading) {
    return <DropTickerTitle />;
  }

  return (
    <>
      <BlueLightning />
      <DropTickerTitle>
        {hotel} @ {eventName}
      </DropTickerTitle>
      <BlueLightning />
    </>
  );
};

export const DropsTicker = () => {
  const { data, isLoading, error } = useDrops();

  if (error) {
    return null;
  }

  if (isLoading) {
    return <Marquee loading={isLoading} />;
  }

  const now = Date.now();
  const drops = data?.drops as DropsV1EndpointResponseItem[];
  const upcomingDrops = drops.filter((drop) => Date.parse(drop?.release_at) > now);
  const noDrops = upcomingDrops.length === 0;

  if (noDrops) {
    return null;
  }

  return (
    <Marquee loading={false}>
      {upcomingDrops.map((drop, index) => {
        const key = `${drop.id}-${index}`;
        const hotel = drop.hotel.name.toLowerCase();
        const eventId = drop.event.id;
        const dropTime = timestampToDate(drop.release_at);
        return (
          <Fragment key={key}>
            <DropsTickerEvent hotel={hotel} eventId={eventId} />
            <DropsCountdown whiteText={true} type='ticker' dropTime={dropTime} />
          </Fragment>
        );
      })}
    </Marquee>
  );
};
