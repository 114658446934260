import { styled } from "@hoken/core/styles/stitches.config";

export const Time = styled("time", {
  textTransform: "uppercase",

  variants: {
    type: {
      base: {
        fontWeight: "$extraBold",
        fontSize: "$medium",
        lineHeight: "$medium",
      },
      ticker: {
        minWidth: "8.4375rem",
        textAlign: "center",
        display: "inline-block",
        color: "$hoken-core-text-color",
        fontSize: "$hoken-core-font-13",
        lineHeight: "$hoken-core-line-height-small",
        fontWeight: "$regular",
        fontFamily: "$helvetica",
      },
      drop: {
        color: "$hoken-core-text-color",
        fontSize: "$small",
        lineHeight: "1.125rem",
        fontWeight: "$bold",
      },
    },
    whiteText: {
      true: {
        color: "$white",
      },
    },
  },
});

export const CharBox = styled("span", {
  display: "inline-block",
  textAlign: "center",

  variants: {
    width: {
      5: {
        width: "5px",
      },
      10: {
        width: "10px",
      },
      12: {
        width: "12px",
      },
    },
  },
});
