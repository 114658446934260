import { useAuthentication } from "@hoken/core";

import { Event, EventResponse } from "../event.interfaces";

import useSWR from "swr";

const NEXT_PUBLIC_API = process.env.NEXT_PUBLIC_API;

export const useEvent = (
  id?: Event["id"],
  swrOptions?: { revalidateOnFocus?: boolean; revalidateIfStale?: boolean },
) => {
  const { session } = useAuthentication();

  const token = session?.token;
  const endpoint = `${NEXT_PUBLIC_API}/api/v1/events/${id}`;
  // TODO: Look into why shorthand property names causes an error on error retry
  const request = token && id ? { endpoint: endpoint, token: token } : null;
  const { data, error } = useSWR<EventResponse>(request, swrOptions);
  const isLoading = !data && !error;

  return { data: data?.data, isLoading, error };
};
