import { styled } from "@hoken/core/styles/stitches.config";

export const AvatarStyle = styled("div", {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  lineHeight: "$large",
  textAlign: "center",
  verticalAlign: "middle",
  textTransform: " uppercase",
  backgroundColor: "none",
  fontFamily: "$helvetica",
  fontSize: "$hoken-core-font-12",
  borderRadius: "100%",
  border: "1.8px solid $white",
  width: "32px",
  height: "32px",
  position: "relative",
  // padding: "1.2rem 0.7rem 1.05rem", //The weird padding bottom is to deal with the line height of Helvetica Neue Pro that's not aligned to baseline.
  svg: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: "50%",
  },
  variants: {
    textWhite: {
      true: {},
    },
    loading: {
      true: {
        backgroundColor: "transparent",
        fontWeight: "$regular",
        width: "6rem",
        color: "$black",
      },
    },
    isAuthenticated: {
      true: {
        color: "$green",
        borderColor: "$green",
      },
      false: {
        color: "$white",
        borderColor: "$white",
      },
    },
    lightTheme: {
      true: {
        color: "$primary",
        borderColor: "$primary",
      },
      false: {
        color: "$primary",
        borderColor: "$primary",
      },
    },
  },
  compoundVariants: [
    {
      lightTheme: false,
      isAuthenticated: true,
      css: {
        color: "$green",
        borderColor: "$green",
      },
    },
    {
      lightTheme: true,
      isAuthenticated: true,
      css: {
        color: "$primary",
        borderColor: "$primary",
      },
    },
    {
      lightTheme: false,
      isAuthenticated: false,
      css: {
        color: "$white",
        borderColor: "$white",
      },
    },
  ],
});
