export const CONTENT = {
  followus: "Follow Us:",
  title: "Hoken",
  tagline: "Hotels where you </br> want to be",
  menu: [
    {
      name: "Explore events",
      link: "/events",
    },
    {
      name: "Sign In",
      link: "/sign-in",
    },
  ],
  menuSigned: [
    {
      name: "Explore events",
      link: "/events",
    },
    {
      name: "Sell",
      link: "https://buy.gohoken.com/marketplace/",
    },
    {
      name: "Roomfolio",
      link: "/roomfolio",
    },
  ],
};
