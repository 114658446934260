import { styled } from "@hoken/core/styles/stitches.config";

import { SVGIconProps } from "../icons.interfaces";

/* istanbul ignore next */
export const HamburgerSVG = ({ title, titleId, onClick, ...props }: SVGIconProps) => (
  <svg
    viewBox='0 0 20 22'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    onClick={onClick}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect y='0.949951' width='20' height='2.7' fill='currentColor' />
    <rect y='9.6499' width='20' height='2.7' fill='currentColor' />
    <rect y='18.35' width='20' height='2.7' fill='currentColor' />
  </svg>
);

export const Hamburger = styled(HamburgerSVG, {
  height: "1.25rem",
  width: "1.25rem",
  margin:
    "$hoken-core-space-none $hoken-core-space-small $hoken-core-space-none $hoken-core-space-none",
  display: "initial",
});
