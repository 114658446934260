import { styled } from "@hoken/core/styles/stitches.config";

// Wrapper prevents cumulative layout shift on initial render
export const MarqueeWrapper = styled("div", {
  backgroundColor: "$primary",
  padding: "$hoken-core-space-none",
  color: "$white",
  ".marquee": {
    minWidth: "auto",
  },

  ".marquee-container": {
    backgroundColor: "$primary",
    padding: "$hoken-core-space-none $hoken-core-space-small",
    transition: "height 0.3s ease-in 1s",
  },

  variants: {
    open: {
      false: {
        ".marquee-container": {
          height: "2rem",
        },
      },
      true: {
        ".marquee-container": {
          height: "0",
        },
      },
    },
  },
});
