import { useCallback, useEffect, useReducer } from "react";

import {
  CountdownAction,
  CountdownState,
} from "../drops-carousel/drops-carousel.interface";

import { DateTime, Duration } from "luxon";

export const initialCountdownState: CountdownState = {
  countdown: undefined,
  atZero: undefined,
};

export const countdownReducer = (state: CountdownState, action: CountdownAction) => {
  switch (action.type) {
    case "SET_COUNTDOWN":
      return { ...state, countdown: action.payload };
    case "SET_AT_ZERO":
      return { ...state, atZero: action.payload };
    default:
      return state;
  }
};

/**
 * Custom hook that will caculate the delta between the current time and a future time
 * @param dropTime - Timestamp in UTC represented as a string
 */
export const useCountdown = (dropTime: Date) => {
  // Configure the interval cadence in milliseconds for the countdown
  const tick = 1000;
  const dropTimeToString = dropTime.toUTCString();
  const dropTimeInMillis = Date.parse(dropTimeToString);

  const [state, dispatch] = useReducer(countdownReducer, initialCountdownState);

  // set method resolves an issue with precision that causes 0 to be bypassed, from 1 to -1
  const luxonDropTime = DateTime.fromMillis(dropTimeInMillis).set({
    millisecond: 0,
  });

  const ticker = useCallback(
    (countdownInterval: NodeJS.Timer | null = null) => {
      const dynamicTime = DateTime.now().toUTC().set({ millisecond: 0 });
      const countdownDifference = luxonDropTime.diff(dynamicTime).toObject();
      const countdownDuration = Duration.fromObject(countdownDifference);
      const clearCountdownInterval = dynamicTime >= luxonDropTime;

      dispatch({
        type: "SET_COUNTDOWN",
        payload: countdownDuration,
      });

      if (clearCountdownInterval) {
        dispatch({
          type: "SET_AT_ZERO",
          payload: true,
        });
        countdownInterval && clearInterval(countdownInterval);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  useEffect(() => {
    ticker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      ticker(countdownInterval);
    }, tick);
    return () => clearInterval(countdownInterval);
  }, [luxonDropTime, ticker]);

  return {
    display: state.countdown?.toFormat("dD : hhH : mm'M' : ss'S'"),
    dateTime: state.countdown?.toMillis(),
    atZero: state.atZero,
  };
};
