import { styled } from "@hoken/core/styles/stitches.config";

import { SVGIconProps } from "../icons.interfaces";

export const BlueLightningSVG = ({ title, titleId, ...props }: SVGIconProps) => (
  <svg
    width='15'
    height='21'
    viewBox='0 0 15 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d='M7.5 9L9 0L0 12H7.5L6 21L15 9H7.5Z' fill='#5BFCB8' />
  </svg>
);

export const BlueLightning = styled(BlueLightningSVG, {
  height: "1.25rem",
  width: "1.25rem",
  margin: "$hoken-core-space-none $hoken-core-space-small",
  display: "initial",
});
