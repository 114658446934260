import { DropsCountdownProps } from "../drops-carousel/drops-carousel.interface";
import { useCountdown } from "../hooks/use-countdown";
import { CharBox, Time } from "./drops-countdown.styles";

export const DropsCountdown = ({ dropTime, whiteText, type }: DropsCountdownProps) => {
  const { display, dateTime, atZero } = useCountdown(dropTime);
  const displayCountdown = dropTime && display;
  const formattedDateTime = dateTime?.toString();

  return displayCountdown && !atZero ? (
    <Time
      whiteText={whiteText}
      type={type}
      dateTime={formattedDateTime}
      data-testid='countdown-visible'
    >
      {display.split("").map((char, index) => {
        const key = `${index}-${char}`;
        if (char === "" || char === " ") return <CharBox key={key} width='5' />;
        if (char === "M")
          return (
            <CharBox key={key} width='12'>
              {char}
            </CharBox>
          );
        if (char === ":")
          return (
            <CharBox key={key} width='5'>
              {char}
            </CharBox>
          );
        return (
          <CharBox key={key} width='10'>
            {char}
          </CharBox>
        );
      })}
    </Time>
  ) : (
    <Time whiteText={whiteText} type={type} css={{ visibility: "hidden" }}>
      &nbsp;
    </Time>
  );
};
