import { styled } from "@hoken/core/styles/stitches.config";

import { SVGIconProps } from "../icons.interfaces";

export const TimesSVG = ({ title, titleId, ...props }: SVGIconProps) => (
  <svg
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d='M15.031 12.969c.61.562.61 1.547 0 2.11A1.454 1.454 0 0 1 14 15.5c-.422 0-.797-.14-1.078-.422L8 10.156l-4.969 4.922A1.454 1.454 0 0 1 2 15.5c-.422 0-.797-.14-1.078-.422a1.445 1.445 0 0 1 0-2.11L5.844 8 .922 3.078a1.445 1.445 0 0 1 0-2.11 1.445 1.445 0 0 1 2.11 0L8 5.892 12.922.969a1.445 1.445 0 0 1 2.11 0c.609.562.609 1.547 0 2.11l-4.923 4.968 4.922 4.922Z'
      fill='#000'
    />
  </svg>
);

export const Times = styled(TimesSVG, {
  height: "1.25rem",
  width: "1rem",
  margin: "$hoken-core-space-none",
  display: "initial",
});
